export const linkInfo = {
  1: {
    linkStatusES: "Débil (1)",
    linkStatusEN: "Weak (1)",
    linkColor: "#a4c2f4",
    linkTooltip: "weakTooltip",
  },
  2: {
    linkStatusES: "Medio (2)",
    linkStatusEN: "Medium (2)",
    linkColor: "#3c78d8",
    linkTooltip: "mediumTooltip",
  },
  3: {
    linkStatusES: "Fuerte (3)",
    linkStatusEN: "Strong (3)",
    linkColor: "#1c4587",
    linkTooltip: "strongTooltip",
  },
};
