import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useTranslation } from "react-i18next";
import { isDesktop } from "react-device-detect";

import { Grid } from "@material-ui/core";

const onMobile = !isDesktop || window.innerWidth < 800;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: "rgba(255, 255, 255, 0.87)",
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #000000",
    pointerEvents: "auto"
  }
}))(Tooltip);

const TutorialTooltip = ({ children, title, description, placement, open, index }) => {
  const { i18n } = useTranslation();

  const titleElement = (
    <React.Fragment>
      <Typography color="inherit" align="center">
        {i18n.t(title)}
      </Typography>
      <div>{i18n.t(description)}</div>
      <br />
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <div>{index + 1 + "/11"}</div>
        </Grid>
        <Grid item>
          <ArrowForward fontSize="small" className="clickable"/>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <HtmlTooltip
      title={titleElement}
      placement={placement}
      open={open}
      arrow={!onMobile}
    >
      {children}
    </HtmlTooltip>
  );
};

export default TutorialTooltip;
