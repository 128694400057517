import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { useTranslation, Trans } from "react-i18next";

import Team from "./components/Team";
import Data from "./components/Data";
import TutorialTooltip from "./components/TutorialTooltip";

import "./Explanation.css";

const TheTabs = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main
  }
}))(Tabs);

function Explanation(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { t } = useTranslation();
  return (
    <TutorialTooltip
      title="tutorialSummaryTitle"
      description="tutorialSummaryDescription"
      placement="top"
      open={props.openOnDesktop(10)}
      index={10}
    >
      <div>
        <TheTabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          <Tab
            id="backgroundTab"
            label={<h3 id="Background">{t("background")}</h3>}
          />
          <Tab label={<h3 id="Usage">{t("usage")}</h3>} />
          <Tab label={<h3 id="Data">{t("data")}</h3>} />
          <Tab label={<h3 id="Team">{t("team")}</h3>} />
        </TheTabs>
        <SwipeableViews
          axis={"x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          {/* First tab */}
          <div className="tab-panel" value={value} index={0}>
            <p>
              <Trans i18nKey="background1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sdgs.un.org/goals"
                >
                  (SDGs)
                </a>
                .
              </Trans>
            </p>
            <p>{t("background2")}</p>
            <p>{t("background3")}</p>
            <p>{t("background4")}</p>
            <p>{t("background5")}</p>
            <p>
              <Trans i18nKey="background6">
                Strong text
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.co-galapagos.org"
                >
                  www.co-galapagos.org
                </a>
              </Trans>
            </p>
          </div>
          {/* Second tab */}
          <div className="tab-panel" value={value} index={1}>
            <p>{t("usage1")}</p>
            <p>{t("usage2")}</p>
            <p>{t("usage3")}</p>
            <p>{t("usage4")}</p>
            <p>{t("usage5")}</p>
            <p>{t("usage6")}</p>
          </div>
          {/* Third tab */}
          <div className="tab-panel" value={value} index={2}>
            <Data />
          </div>
          {/* Fourth tab */}
          <div className="tab-panel" value={value} index={3}>
            <Team />
          </div>
        </SwipeableViews>
      </div>
    </TutorialTooltip>
  );
}

export default Explanation;
